<template>
  <div class="report">
    <div class="fc report-header">
      <div class="header-container fr">
        <el-radio-group v-model="rightRadioValue" @change="handleChangeRightSection">
          <el-radio-button label="学习报告"></el-radio-button>
          <el-radio-button label="试卷答案"></el-radio-button>
        </el-radio-group>
      </div>
      <div class="fr header-detail">
        <el-radio-group v-model="leftRadioValue" @change="handleChangeLeftSection">
          <el-radio-button label="班级"></el-radio-button>
          <el-radio-button label="年级"></el-radio-button>
        </el-radio-group>
        <h2 class="b tc">学情分析</h2>
      </div>
      <div class="header-desc fr">
        <i class="iconfont icon-zhenduanbaogao"></i>
        <span>诊断报告</span>
      </div>
    </div>
    <div class="section paper-over">
      <div class="section-header">
        <span class="bg"></span>
        <span>试卷概览</span>
      </div>
      <div class="paper-container fc">
        <h4>&nbsp;&nbsp;你的得分：</h4>
        <span class="fr">{{score}}<span>分</span></span>
        <div class="ranking">
          <div class="fr ranking-content">
            <span class="ranking-content-title">得分排名：</span>
            <div class="ranking-content-right">
              <el-progress 
                :show-text="false"
                :stroke-width="20" 
                :percentage="rank"
                color="#00B0FF"
                status="exception" 
              />
              <div class="ranking-content-desc" :style="{left: rank + '%'}">
                <el-tooltip class="item" effect="dark" :content="flag_name" placement="right" v-if="flag_name">
                  <img src="../assets/images/icon_location.png" alt="">
                </el-tooltip>
                <img src="../assets/images/icon_location.png" alt="" v-else>
                <span :class="{'mini-rank': rank < 5}">你的排名：超过了{{rank}}%的同学</span>
              </div>
            </div>
          </div>
          <span class="sjx"></span>
        </div>
      </div>
      <div class="paper-container fc">
        <h4>&nbsp;&nbsp;答卷时长：</h4>
        <span class="fr">{{my_answer_time}}<span>分钟</span></span>
        <div class="ranking">
          <div class="fr ranking-content">
            <span class="ranking-content-title">时长排名：</span>
            <div class="ranking-content-right">
              <el-progress 
                :show-text="false"
                :stroke-width="20" 
                :percentage="answer_time_rank"
                color="#00B0FF"
                status="exception" 
              />
              <div class="ranking-content-desc" :style="{left: answer_time_rank + '%'}">
                <el-tooltip class="item" effect="dark" :content="flag_name" placement="right" v-if="flag_name">
                  <img src="../assets/images/icon_location.png" alt="">
                </el-tooltip>
                <img src="../assets/images/icon_location.png" alt="" v-else>
                <span>你的排名：超过了{{answer_time_rank}}%的同学</span>
              </div>
            </div>
          </div>
          <span class="sjx"></span>
        </div>
      </div>
      <!-- <div class="fc wrong-question-amount">
        <h4>失分难度分布</h4>
        <span>总失分题数：{{answer_false_num.length}}道</span>
        <div class="wrong-diff-echart">
          
        </div>
      </div> -->
    </div>
    <div class="section boruodian">
      <div class="fr boruodian-header">
        <div class="section-header">
          <span class="bg"></span>
          <span>薄弱点诊断</span>
        </div>
      </div>
      <el-switch
        @change="handleSwitchKnow"
        class="switch-know"
        v-model="isFiveKnow"
        inactive-text="三级知识点"
        active-text="五级知识点">
      </el-switch>
      <div class="boruodian-container fr">
        此次表现优秀，未发现薄弱点
      </div>
      <p>*上图展示的是此次试卷你的薄弱<span>知识点</span>、<span>数学方法</span>、<span>数学思想</span>、<span>核心素养</span></p>
      <div class="tip-container">
        <div>
          <span class="b">薄弱点诊断解析：</span><br>
          <span v-html="weak_detail"></span>
          <div class="cover"></div>
        </div>
        <span class="sjx"></span>
      </div>
    </div>
    <div class="fr detail-btn">
      <span @click="detail" class="fr" v-if="isShowDetailBtn">查看详情
        <img src="../assets/images/icon_detail.png" alt="">
      </span>
    </div>
    <div v-if="isShowDetail" class="detail-container">
      <h4 class="fr">
        <i class="iconfont icon-zhenduanbaogao"></i>
        <span>答卷解析<span v-if="flag === 2 || flag === 4">({{flag === 2 ? '班级同档次' : '年级同档次'}})</span></span>
      </h4>
      <div class="paper-score">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span>试卷得分</span>
          </div>
          <div class="card-desc">(满分100分)</div>
          <div class="card-btn fr" @click="isShowPaperScore = !isShowPaperScore" :class="{close : !isShowPaperScore}">
            <i class="iconfont" :class="[isShowPaperScore ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowPaperScore ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="fc" v-show="isShowPaperScore">
          <div class="paper-echart"></div>
          <div class="score-footer fr">
            *此次得分<span class="light">&nbsp;{{score_detail.my_score}}</span>分，该成绩超过了此次参加试卷测试的<span class="light">{{score_detail.my_rank}}%</span>以上的同学
          </div>
        </div>
      </div>

      <div class="paper-diff-analy">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span class="b">答题表现</span>
          </div>
          <div class="card-btn fr" @click="isShowAnswerBiaoxian = !isShowAnswerBiaoxian" :class="{close : !isShowAnswerBiaoxian}">
            <i class="iconfont" :class="[isShowAnswerBiaoxian ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowAnswerBiaoxian ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="fc" v-show="isShowAnswerBiaoxian">
          <div class="answer-echart">
            <div v-for="(item, index) in report_answer_detail" :key="index" ref="scoreSetting">
              <div class="fr score-header-section">
                <span>{{ item.cate_name }}</span>
              </div>
              <el-table
                :data="item.questions"
                class="my-table"
                border
                row-key="question_id"
                :tree-props="{
                  children: 'sub_questions',
                  hasChildren: 'hasChildren',
                }"
              >
                <el-table-column label="题号" align="center" width="150px">
                  <template slot-scope="scope">{{scope.row.order_id}}</template>
                </el-table-column>
                <el-table-column label="知识点" align="center">
                  <template slot-scope="scope">
                    <span class="latex">
                      {{Array.isArray(scope.row.tip_key_name) ? scope.row.tip_key_name.join('，') : scope.row.tip_key_name}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="得分情况" align="center">
                  <template slot-scope="scope">
                    <span :class="{red: scope.row.my_score !== scope.row.total_score, green: scope.row.my_score === scope.row.total_score}">{{scope.row.my_score}}</span>/{{scope.row.total_score}}
                  </template>
                </el-table-column>
                <el-table-column label="平均得分" align="center">
                  <template slot-scope="scope">{{scope.row.student_true_score}}</template>
                </el-table-column>
                <el-table-column label="平均得分率" align="center">
                  <template slot-scope="scope">{{scope.row.student_true_runk === '' ? '' : parseInt(scope.row.student_true_runk * 100) + '%'}}</template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="paper-diff-analy">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span class="b">答题难度分析</span>
          </div>
          <div class="card-btn fr" @click="isShowPaperDiffAnaly = !isShowPaperDiffAnaly" :class="{close : !isShowPaperDiffAnaly}">
            <i class="iconfont" :class="[isShowPaperDiffAnaly ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowPaperDiffAnaly ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="fc paper-diff-analy-container" v-show="isShowPaperDiffAnaly">
          <div class="paper-score-echart">

          </div>
          <table>
            <thead>
              <tr>
                <td>难度</td>
                <td>试卷总分</td>
                <td>题目总分</td>
                <td>我的得分</td>
                <td>班级平均得分</td>
                <td rowspan="2">我的得分率</td>
                <td rowspan="2">班级平均得分率</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in question_level_score_data.filter(v => v.score)" :key="index">
                <td>{{item.name}}</td>
                <td :rowspan="question_level_score_data.length" v-if="index === 0">{{report_score}}</td>
                <td>{{item.score}}</td>
                <td>{{item.my_score}}</td>
                <td>{{item.student_avg_score}}</td>
                <td>{{item.my_score_rate}}</td>
                <td>{{item.student_score_rate}}</td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="first-line fr">
            <div class="first-line-left fc">
              <div class="first-line-top">
                <div class="fr detail-header">
                  <span class="line"></span>
                  <span class="b">试卷难易度分布</span>
                </div>
                <div 
                  class="fr top-row" 
                  v-for="item in question_level.filter(v => v.score !== 0)"
                  :key="item.id"
                >
                  <div class="fr">
                    <span>{{item.title}}：</span>
                  </div>
                  <span class="b">{{item.score}}分（{{parseInt(item.rank*100)}}%）</span>
                </div>
              </div>
              <div class="first-line-left-bottom-echart">
                
              </div>
            </div>
            <div class="first-line-right">
              <div class="first-line-top">
                <div class="fr detail-header">
                  <span class="line"></span>
                  <span class="b">我的错题难易度占比</span>
                </div>
                <div 
                  class="fr top-row" 
                  v-for="item in my_false_question_level.filter(v => v.score !== 0)"
                  :key="item.id"
                >
                  <div class="fr">
                    <span>{{item.title}}：</span>
                  </div>
                  <span class="b">{{item.score}}分（{{parseInt(item.rank*100)}}%）</span>
                </div>
              </div>
              <div class="first-line-right-bottom-echart">
                
              </div>
            </div>
          </div>
          <div class="second-line fr">
            <div class="fc second-line-top">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">准确率对比</span>
              </div>
              <div class="correct-comp-echart">

              </div>
            </div>
            <div class="fc second-line-bottom">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">答题难度对比（答卷难度数值越大代表越难）</span>
              </div>
              <div class="answer-diff-echart">

              </div>
            </div>
          </div>
          <div class="tip-container">
            <div>
              <span class="b">准确率解析：</span>
              <span>
                根据你的答卷情况，你对<span 
                  v-for="(item, index) in report_level.report_detail.filter(v => v.my_true_runk !== '0.00' || v.student_true_runk !== '0.00')" 
                  :key="index">{{item.title}}难度的题目正确率为{{getNumber(item.my_true_runk * 100)}}%，{{leftRadioValue}}平均正确率为{{getNumber(item.student_true_runk * 100)}}%{{index === report_level.report_detail.length - 1 ? '；' : '。'}}</span>
              </span>
            </div>
            <span class="sjx"></span>
          </div> -->
        </div>
      </div>
      <div class="answer-paper-duration fc">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span>答卷时长</span>
          </div>
          <div class="card-btn fr" @click="isShowAnswerPaperDurationAnaly = !isShowAnswerPaperDurationAnaly" :class="{close : !isShowAnswerPaperDurationAnaly}">
            <i class="iconfont" :class="[isShowAnswerPaperDurationAnaly ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowAnswerPaperDurationAnaly ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="fc" v-show="isShowAnswerPaperDurationAnaly">
          <div class="answer-paper-echart">
            
          </div>
        </div>
      </div>
      <div class="know-anlay">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span>知识点分析</span>
          </div>
          <div class="card-btn fr" @click="isShowKnowAnaly = !isShowKnowAnaly" :class="{close : !isShowKnowAnaly}">
            <i class="iconfont" :class="[isShowKnowAnaly ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowKnowAnaly ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="know-anlay-container fc" v-show="isShowKnowAnaly">
          <div class="know-anlay-top fc" v-if="my_false_know_list.list.length">
            <div class="fr detail-header">
              <span class="line"></span>
              <span class="b">知识点掌握情况对比</span>
            </div>
            <div class="know-anlay-echart">

            </div>
            <div class="tip-container">
              <div>
                <span class="b">分析：</span>
                <span>{{my_false_know_list.know_summary}}</span>
              </div>
              <span class="sjx"></span>
            </div>
          </div>
          <div class="know-anlay-bottom fc">
            <div class="fr detail-header">
              <span class="line"></span>
              <span class="b">试卷知识点分布</span>
            </div>
            <el-switch
              @change="handleSwitchKnow"
              class="switch-know"
              v-model="isFiveKnow"
              inactive-text="三级知识点"
              active-text="五级知识点">
            </el-switch>
            <div class="know-container" :class="{fr: !app.isPad, isPad: app.isPad}">
              <div class="first-level-know">
                
              </div>
              <div class="second-level-know" v-if="app.isPad ? knowledge_level_2.length : true">

              </div>
              <div class="thrid-level-know" v-if="app.isPad ? knowledge_level_3.length : true">
                
              </div>
              <div class="four-level-know" v-if="knowledge_level_4.length">
                
              </div>
              <div class="five-level-know" v-if="app.isPad ? knowledge_level_5.length : (knowledge_level_4.length || knowledge_level_5.length)">
                
              </div>
            </div>
            <p>*点击首张环状图可显示下一级知识点</p>
          </div>
        </div>
      </div>
      <div class="analy" v-if="math_methods.length || question_math_methods.length">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span>数学方法分析</span>
          </div>
          <div class="card-btn fr" @click="isShowMethodsAnaly = !isShowMethodsAnaly" :class="{close : !isShowMethodsAnaly}">
            <i class="iconfont" :class="[isShowMethodsAnaly ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowMethodsAnaly ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="anlay-container fc" v-show="isShowMethodsAnaly">
          <div class="anlay-container-top fc">
            <div class="anlay-container-top-left" v-if="math_methods.length">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">试卷数学方法考核分布</span>
              </div>
              <div class="anlay-container-top-left-echart method-left-echart">
                
              </div>
            </div>
            <div class="anlay-container-top-right">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">数学方法掌握情况对比</span>
              </div>
              <div class="anlay-container-top-right-echart anlay-container-top-right-method">
                
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <td>数学方法</td>
                  <td>
                    <div class="fr head-sort">
                      <span>题目总分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortMethod($event, 'question_total_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortMethod($event, 'question_total_score')" />
                      </div>
                    </div>
                  </td>
                  <td>题目序号</td>
                  <td>我得分的题目</td>
                  <td>
                    <div class="fr head-sort">
                      <span>我的得分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortMethod($event, 'my_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortMethod($event, 'my_score')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>班级平均得分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortMethod($event, 'student_avg_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortMethod($event, 'student_avg_score')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>我的得分率</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortMethod($event, 'my_score_runk')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortMethod($event, 'my_score_runk')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>班级平均得分率</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortMethod($event, 'student_avg_score_runk')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortMethod($event, 'student_avg_score_runk')" />
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in question_math_methods" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.question_total_score}}</td>
                  <td>{{item.questions_order.join('，')}}</td>
                  <td>{{item.my_questions_order.join('，')}}</td>
                  <td>{{item.my_score}}</td>
                  <td>{{item.student_avg_score}}</td>
                  <td>{{item.my_score_runk}}%</td>
                  <td>{{item.student_avg_score_runk}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tip-container">
            <div>
              <span class="b">分析：</span>
              <span>您对上述数学方法掌握的情况较差，建议加深对以上数学方法的理解，同时多实践做题，从而实现融汇贯通这些数学方法的目标。</span>
            </div>
            <span class="sjx"></span>
          </div>
        </div>
      </div>
      <div class="analy" v-if="math_thought.length || question_math_thought.length">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span>数学思想分析</span>
          </div>
          <div class="card-btn fr" @click="isShowThoughtAnaly = !isShowThoughtAnaly" :class="{close : !isShowThoughtAnaly}">
            <i class="iconfont" :class="[isShowThoughtAnaly ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowThoughtAnaly ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="anlay-container fc" v-show="isShowThoughtAnaly">
          <div class="anlay-container-top fc">
            <div class="anlay-container-top-left" v-if="math_thought.length">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">试卷数学思想考核分布</span>
              </div>
              <div class="anlay-container-top-left-echart thought-left-echart">

              </div>
            </div>
            <div class="anlay-container-top-right">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">数学思想掌握情况对比</span>
              </div>
              <div class="anlay-container-top-right-echart anlay-container-top-right-thought">
                
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <td>数学思想</td>
                  <td>
                    <div class="fr head-sort">
                      <span>题目总分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortThought($event, 'question_total_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortThought($event, 'question_total_score')" />
                      </div>
                    </div>
                  </td>
                  <td>题目序号</td>
                  <td>我得分的题目</td>
                  <td>
                    <div class="fr head-sort">
                      <span>我的得分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortThought($event, 'my_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortThought($event, 'my_score')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>班级平均得分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortThought($event, 'student_avg_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortThought($event, 'student_avg_score')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>我的得分率</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortThought($event, 'my_score_runk')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortThought($event, 'my_score_runk')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>班级平均得分率</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortThought($event, 'student_avg_score_runk')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortThought($event, 'student_avg_score_runk')" />
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in question_math_thought" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.question_total_score}}</td>
                  <td>{{item.questions_order.join('，')}}</td>
                  <td>{{item.my_questions_order.join('，')}}</td>
                  <td>{{item.my_score}}</td>
                  <td>{{item.student_avg_score}}</td>
                  <td>{{item.my_score_runk}}%</td>
                  <td>{{item.student_avg_score_runk}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tip-container">
            <div>
              <span class="b">分析：</span>
              <span>您对上述数学思想掌握的情况较差，建议加深对以上数学思想的理解，同时多实践做题，从而实现融汇贯通这些数学思想的目标。</span>
            </div>
            <span class="sjx"></span>
          </div>
        </div>
      </div>
      <div class="analy" v-if="math_ability.length || question_math_ability.length">
        <div class="fr card-header">
          <div class="section-header">
            <span class="bg"></span>
            <span>核心素养分析</span>
          </div>
          <div class="card-btn fr" @click="isShowAbilityAnaly = !isShowAbilityAnaly" :class="{close : !isShowAbilityAnaly}">
            <i class="iconfont" :class="[isShowAbilityAnaly ? 'icon-toparrow' : 'icon-bottomarrow']"></i>
            <span>{{isShowAbilityAnaly ? '收起' : '展开'}}</span>
          </div>
        </div>
        <div class="anlay-container fc" v-show="isShowAbilityAnaly">
          <div class="anlay-container-top fc">
            <div class="anlay-container-top-left" v-if="math_ability.length">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">试卷核心素养考核分布</span>
              </div>
              <div class="anlay-container-top-left-echart ability-left-echart">

              </div>
            </div>
            <div class="anlay-container-top-right">
              <div class="fr detail-header">
                <span class="line"></span>
                <span class="b">核心素养掌握情况对比</span>
              </div>
              <div class="anlay-container-top-right-echart anlay-container-top-right-ability">
                
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <td>核心素养</td>
                  <td>
                    <div class="fr head-sort">
                      <span>题目总分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortAbility($event, 'question_total_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortAbility($event, 'question_total_score')" />
                      </div>
                    </div>
                  </td>
                  <td>题目序号</td>
                  <td>我得分的题目</td>
                  <td>
                    <div class="fr head-sort">
                      <span>我的得分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortAbility($event, 'my_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortAbility($event, 'my_score')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>班级平均得分</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortAbility($event, 'student_avg_score')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortAbility($event, 'student_avg_score')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>我的得分率</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortAbility($event, 'my_score_runk')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortAbility($event, 'my_score_runk')" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="fr head-sort">
                      <span>班级平均得分率</span>
                      <div class="fc">
                        <i class="el-icon-caret-top" @click="handleAscSortAbility($event, 'student_avg_score_runk')" />
                        <i class="el-icon-caret-bottom" @click="handleDesSortAbility($event, 'student_avg_score_runk')" />
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in question_math_ability" :key="index">
                  <td>{{item.name}}</td>
                  <td>{{item.question_total_score}}</td>
                  <td>{{item.questions_order.join('，')}}</td>
                  <td>{{item.my_questions_order.join('，')}}</td>
                  <td>{{item.my_score}}</td>
                  <td>{{item.student_avg_score}}</td>
                  <td>{{item.my_score_runk}}%</td>
                  <td>{{item.student_avg_score_runk}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tip-container">
            <div>
              <span class="b">分析：</span>
              <span>您对上述核心素养掌握的情况较差，建议加深对以上核心素养的理解，同时多实践做题，从而实现融汇贯通这些核心素养的目标。</span>
            </div>
            <span class="sjx"></span>
          </div>
        </div>
      </div>
      <div class="tc footer">
        <span @click="handleClickFooter">
          收起
          <img src="../assets/images/icon_detail2.png" alt="">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {analyInfo, analyDetail, getKnowledgeLevel} from 'api'
import { ERROR_CODE } from '@/utils/config'
import { deepClone } from '@/utils/util'

const COLORS = ['#00B0FF', '#FF9858', '#C176EC']

export default {
  data() {
    return {
      // 是否显示详情按钮
      isShowDetailBtn: true,
      leftRadioValue: '班级',
      rightRadioValue: '学习报告',
      id: null,
      isBindEvtObj: {
        1: false, // 2级
        2: false, // 3级
        3: false, // 4级
        4: false, // 5级
      },
      isShowDetail: false,
      // 失分题数
      answer_false_num: [],
      // 答题表现
      report_answer_detail: [],
      // 试卷得分
      isShowPaperScore: true,
      // 答题表现
      isShowAnswerBiaoxian: true,
      // 试卷难易度分析
      isShowPaperDiffAnaly: true,
      // 答卷时长分析
      isShowAnswerPaperDurationAnaly: true,
      // 知识点分析
      isShowKnowAnaly: true,
      // 数学方法分析
      isShowMethodsAnaly: true,
      // 核心素养分析
      isShowAbilityAnaly: true,
      // 数学思想分析
      isShowThoughtAnaly: true,
      // 分值
      score: 0,
      // 排名
      rank: 0,
      flag_name: '',
      // 答卷时长
      my_answer_time: 0,
      // 答卷时长排名
      answer_time_rank: 0,
      answer_list: [],
      // 薄弱点诊断
      weak_list: [],
      // 学习建议
      proposal: '',
      // 试卷得分
      score_list: [],
      score_detail: {},
      // 试卷难易度分布
      question_level: [],
      // 我的错题难易度占比
      my_false_question_level: [],
      // 我的准确率
      my_true_question_rank: [],
      // 答题难度对比
      report_level: {
        report_detail: []
      },
      // 我与同学掌握差距较大的知识点
      my_false_know_list: {
        list: [],
        know_summary: ''
      },
      // 一级知识点
      knowledge_level_1: [],
      // 二级知识点
      knowledge_level_2: [],
      // 三级知识点
      knowledge_level_3: [],
      // 四级知识点
      knowledge_level_4: [],
      // 五级知识点
      knowledge_level_5: [],
      // 数学方法分布
      math_methods: [],
      // 数学方法掌握情况对比
      question_math_methods: [],
      // 数学思想分布
      math_thought: [],
      // 数学思想掌握情况对比
      question_math_thought: [],
      // 核心素养分布
      math_ability: [],
      // 核心素养掌握情况对比
      question_math_ability: [],
      // 答卷时长
      time_list: [],
      flag: null,
      // 是否五级知识点
      isFiveKnow: true,
      // 试卷得分情况对比
      question_level_score_data: [],
      // 试卷总分
      report_score: 0
    }
  },
  created () {
    this.id = this.$route.query.id
  },
  mounted () {
    this.init()
  },
  inject: ['app'],
  computed: {
    type() {
      return this.leftRadioValue === '班级' ? 0 : 1 
    },
    originWeakList() {
      if (this.isFiveKnow) {
        return this.weak_list[1].list
      } else {
        return this.weak_list[0].list
      }
    },
    weak_detail() {
      if (!this.weak_list.length) {
        return null
      }
      if (this.isFiveKnow) {
        return this.weak_list[1].detail
      } else {
        return this.weak_list[0].detail
      }
    }
  },
  methods: {
    getTargetKey(key) {
      return key + 'target'
    },
    getTarget(key) {
      return this[this.getTargetKey(key)]
    },
    setCopyList(listKey) {
      if (!this['_' + listKey]) {
        this['_' + listKey] = deepClone(this[listKey])
      }
    },
    handleSort(e, listKey, key, flag) {
      let target = e.target
      if (this.getTarget(listKey) && this.getTarget(listKey) !== target) {
        let cName = this.getTarget(listKey).className
        if (cName.indexOf('active') >= 0) {
          this.getTarget(listKey).className = cName.slice(0, cName.length - 7)
        }
      }

      this[this.getTargetKey(listKey)] = target
      let className = target.className
      if (className.indexOf('active') >= 0) { // 恢复原表格
        target.className = className.slice(0, className.length - 7)
        this[this.getTargetKey(listKey)] = null
        this[listKey] = deepClone(this['_' + listKey])
      } else { // 对表格进行升序
        target.className += ' active'
        if (flag) {
          this.setCopyList(listKey)
          this[listKey] = this[listKey].sort((a, b) => a[key] - b[key])
        } else {
          this.setCopyList(listKey)
          this[listKey] = this[listKey].sort((a, b) => b[key] - a[key])
        }
      }
    },
    // 方法升序
    handleAscSortMethod(e, key) {
      this.handleSort(e, 'question_math_methods', key, true)
    },
    // 方法降序
    handleDesSortMethod(e, key) {
      this.handleSort(e, 'question_math_methods', key, false)
    },
    // 思想升序
    handleAscSortThought(e, key) {
      this.handleSort(e, 'question_math_thought', key, true)
    },
    // 思想降序
    handleDesSortThought(e, key) {
      this.handleSort(e, 'question_math_thought', key, false)
    },
    // 素养升序
    handleAscSortAbility(e, key) {
      this.handleSort(e, 'question_math_ability', key, true)
    },
    // 素养降序
    handleDesSortAbility(e, key) {
      this.handleSort(e, 'question_math_ability', key, false)
    },
    // 切换知识点
    handleSwitchKnow() {
      this.initWordCloundEchart()
      this.initKnowEchart()
    },
    // 初始化知识点分布
    initKnowEchart() {
      let newArr = this.knowledge_level_1
      this.knowledge_level_1 = []
      this.knowledge_level_2 = []
      this.knowledge_level_3 = []
      this.knowledge_level_4 = []
      this.knowledge_level_5 = []

      this.isBindEvtObj[1] = false
      this.isBindEvtObj[2] = false
      this.isBindEvtObj[3] = false
      this.isBindEvtObj[4] = false

      this.myChart1 && this.myChart1.dispose()
      this.myChart2 && this.myChart2.dispose()
      this.myChart3 && this.myChart3.dispose()
      this.myChart4 && this.myChart4.dispose()
      this.myChart5 && this.myChart5.dispose()

      this.$nextTick(() => {
        this.knowledge_level_1 = newArr
        this.initFirstWrongKnowEchart()
      })
    },
    getNumber(val) {
      return parseInt(val)
    },
    handleClickFooter() {
      this.isShowDetail = false
      this.isShowDetailBtn = true
    },
    detail() {
      this.isShowDetail = !this.isShowDetail
      if (this.isShowDetail) {
        this.showEchart()
        this.reqDetail()
      }
      this.isShowDetailBtn = false
    },
    handleAnswerDetail(list) {
      list.forEach(v => {
        if (v.question_type === 400) {
          v.questions.forEach(cv => {
            if (cv.has_child === 0) {
              cv.sub_questions = cv.spaces.map(v => ({...v, question_id: v.order_id}))
            } else {
              cv.sub_questions.forEach(scv => {
                scv.sub_questions = scv.spaces.map(v => ({...v, question_id: v.order_id}))
              })
            }
          })
        }
      })
      return list
    },
    /**获取详情 */
    async reqDetail() {
      let {error, data} = await analyDetail({
        report_id: this.id,
        type: this.type
      })
      if (error === ERROR_CODE) {
        this.score_list = data?.score_list || []
        this.score_detail = data?.score_detail || {} 
        this.question_level = data?.question_level || []
        this.my_false_question_level = data?.my_false_question_level || []

        this.flag = data.flag
        
        this.my_true_question_rank = data?.my_true_question_rank || []
        this.report_level = data?.report_level || {}

        this.my_false_know_list = data?.my_false_know_list || {
          list: [],
          know_summary: ''
        }
        this.knowledge_level_1 = data?.my_all_false_know_list || []

        this.time_list = data?.time_list || []

        this.math_methods = data?.math_methods || []
        this.question_math_methods = data?.question_math_methods || []
        
        this.math_thought = data?.math_thought || []
        this.question_math_thought = data?.question_math_thought || []
        
        this.math_ability = data?.math_ability || []
        this.question_math_ability = data?.question_math_ability || []

        this.question_level_score_data = data?.question_level_score_data || []
        this.report_score = data.report_score

        this.initPaperScoreCompareEchart()

        this.report_answer_detail = this.handleAnswerDetail(data.report_answer_detail)
        
        this.initPaperScoreEchart()
        this.initPaperDiffEchart()
        this.initWrongDiffEchart()

        this.initCorrectEchart()
        this.initAnswerDiffEchart()

        // 答卷时长
        this.initAnswerDurationEchart()

        this.$nextTick(() => {
          // 我与同学掌握差距较大的知识点
          this.initMyKnowEchart()
        })

        this.initFirstWrongKnowEchart()
      
        this.$nextTick(() => {
          // 数学方法
          this.initLeftEchart('.method-left-echart', this.math_methods)
          this.initRightEchart('.anlay-container-top-right-method', this.question_math_methods)
          
          // 数学思想
          this.initLeftEchart('.thought-left-echart', this.math_thought)
          this.initRightEchart('.anlay-container-top-right-thought', this.question_math_thought)
          
          // 核心素养
          this.initLeftEchart('.ability-left-echart', this.math_ability)
          this.initRightEchart('.anlay-container-top-right-ability', this.question_math_ability)
        })
      }
    },
    // 试卷得分情况对比
    initPaperScoreCompareEchart() {
      let elem = document.querySelector('.paper-score-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      const COLORS = ['#00B0FF', '#FF9858', '#C176EC']
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: '0',
          icon: "circle",
          itemHeight: 6, // 修改icon图形大小
          itemGap: 24,
          textStyle: {
            fontSize: 16,
            color: "#333",
            padding: [0, 0, 0, -8], // 修改文字和图标距离
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.question_level_score_data.filter(v => v.score).map(v => v.name),
          axisLabel: {
            fontSize: 16
          },
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '题目总分',
            type: 'bar',
            data: this.question_level_score_data.filter(v => v.score).map(v => v.score),
            itemStyle: {
              normal: {
                color: '#00B0FF'
              }
            },
            barWidth: '40',
            barMinHeight: 5,
            barGap: '50%'
          },
          {
            name: '我的得分',
            type: 'bar',
            data: this.question_level_score_data.filter(v => v.score).map(v => v.my_score),
            itemStyle: {
              normal: {
                color: '#FF9858'
              }
            },
            barWidth: '40',
            barMinHeight: 5,
            barGap: '50%'
          },
          {
            name: '班级平均得分',
            type: 'bar',
            data: this.question_level_score_data.filter(v => v.score).map(v => v.student_avg_score),
            itemStyle: {
              normal: {
                color: '#C176EC'
              }
            },
            barWidth: '40',
            barMinHeight: 5,
            barGap: '50%'
          }
        ]
      })
    },
    /**答卷时长 */
    initAnswerDurationEchart() {
      let elem = document.querySelector('.answer-paper-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        legend: {
        },
        grid: {
          top: '2%',
          left: '3%',
          right: '4%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          data: this.time_list.map(v => v.title),
          axisLabel: {
            fontSize: 16
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 45,
            data: this.time_list.map(v => ({
              value: v.score,
              itemStyle: {
                color: v.color
              },
              label: {
                show: true,
                position: 'right',
                formatter:'{c}分钟',
                textStyle: {
                  fontSize: 16,
                  color: v.color
                }
              }
            }))
          }
        ]
      })
    },
    initLeftEchart(cName, list) {
      let elem = document.querySelector(cName)
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        title: {
          left: 'center',
          textStyle: {
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {d}%',
          textStyle: {
            fontSize: 16
          },
          confine: 'true'
        },
        legend: {
          left: '0%',
          show: false
        },
        series: [
          {
            name,
            type: 'pie',
            radius: ['40%', '70%'],
            selectedMode: 'single',
            data: list.map((v, i) => ({value: v.num, name: v.name})),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              show: true,
              textStyle: {
                fontSize: 16
              }
            }
          }
        ]
      })
    },
    initRightEchart(cName, list) {
      let elem = document.querySelector(cName)
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      const COLORS = ['#00B0FF', '#FF9858', '#C176EC']
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        legend: {
          bottom: '0',
          icon: "circle",
          itemHeight: 6, // 修改icon图形大小
          itemGap: 24,
          textStyle: {
            fontSize: 16,
            color: "#333",
            padding: [0, 0, 0, -8], // 修改文字和图标距离
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: list.map(v => v.name),
          axisLabel: { interval: 0 },
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        series: ['题目分值', '我的得分', '班级平均得分'].map((v, i) => ({
          name: v,
          type: 'bar',
          data: list.map(cv => cv[i === 0 ? 'question_total_score' : i === 1 ? 'my_score' : 'student_avg_score']),
          barWidth: 30,
          barMinHeight: 5,
          itemStyle: {
            normal: {
              color: COLORS[i]
            }
          },
          barGap: '50%'
        }))
      })
    },
    /**切换左边按钮 */
    handleChangeLeftSection(v) {
      this.reset()
      this.init()
    },
    showEchart() {
      this.isShowDetail = true
      this.isShowPaperScore = true
      this.isShowAnswerBiaoxian = true
      this.isShowPaperDiffAnaly = true
      this.isShowKnowAnaly = true
      this.isShowMethodsAnaly = true
      this.isShowAbilityAnaly = true
      this.isShowThoughtAnaly = true
      this.isShowAnswerPaperDurationAnaly = true
    },
    reset() {
      this.isShowDetailBtn = true
      this.isShowDetail = false
      this.isShowPaperScore = false
      this.isShowAnswerBiaoxian = false
      this.isShowPaperDiffAnaly = false
      this.isShowKnowAnaly = false
      this.isShowMethodsAnaly = false
      this.isShowAbilityAnaly = false
      this.isShowThoughtAnaly = false
      this.isShowAnswerPaperDurationAnaly = false
      this.weak_list = []
    },
    getMq() {
      if (!this.MQ) {
        this.MQ = MathQuill.getInterface(2);
      }
      return this.MQ;
    },
    /**渲染latex */
    renderLatex() {
      this.$nextTick(() => {
        let elems = document.querySelectorAll('.latex')
        elems.forEach(v => this.getMq().StaticMath(v))
      })
    },
    // 组装知识点/数学方法/思想/能力分析
    getOriginAnalyze(str, key, idx) {
      let arr = []

      let firstIdx = str.indexOf('#')
      let secondIdx = str.indexOf('$')
      if (firstIdx > 0 && secondIdx > 0) {
        let tmps = str.split('#')
        arr.push({
          text: tmps[0]
        })
        arr.push({
          text: tmps[1],
          flag: 1
        })
        tmps = tmps[2].split('$')
        tmps.forEach(v => arr.push({text: v}))
        arr[3].flag = 2
      } else if (firstIdx > 0 && secondIdx === -1) { // 掌握的知识点
        let tmps = str.split('#')
        tmps.forEach(v => arr.push({text: v}))
        arr.forEach((v, i) => {
          if (i % 2 === 1) {
            arr[i].flag = 1
          }
        })
      } else if (secondIdx >0 && firstIdx === -1) { // 错误的知识点
        let tmps = str.split('$')
        tmps.forEach(v => arr.push({text: v}))
        arr.forEach((v, i) => {
          if (i % 2 === 1) {
            arr[i].flag = 2
          }
        })
      } else {
        arr.push({text: str})
      }
      if (idx !== undefined) {
        this[key][idx] = arr
      } else {
        this[key] = arr
      }
    },
    /**失分率 */
    initWrongDiffDistrEchart() {
      let elem = document.querySelector('.wrong-diff-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      
      myChart.setOption({
        color: ['#E3B2FF', '#DAEFF9'],
        // title: {
        //   text: '失分难度分布情况',
        //   left: '0',
        //   textStyle: {
        //     fontWeight: 'normal'
        //   }
        // },
        legend: {
          bottom: 0
        },
        grid: {
          top: '0%',
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 16
          },
        },
        yAxis: {
          type: 'category',
          data: this.answer_list.filter(v => v.total_score !== 0).map(v => v.title),
          axisLabel: {
            fontSize: 16
          }
        },
        series: [
          {
            type: 'bar',
            name: '我的失分    ',
            stack: 'total',
            barMaxWidth: 50,
            emphasis: {
              focus: 'series'
            },
            data: this.answer_list.filter(v => v.total_score !== 0).map(v => ({
              value: v.false_score,
              label: {
                show: true,
                color: '#E633E0',
                formatter: function() {
                  return v.false_score === 0 ? '' : v.false_score + '分'
                },
                position: v.false_score < 5 ? 'right' : 'inside'
              },
              itemStyle: {
                color: '#E3B2FF'
              }
            }))
          },
          {
            type: 'bar',
            name: '题目总分',
            stack: 'total',
            barMaxWidth: 50,
            emphasis: {
              focus: 'series'
            },
            data: this.answer_list.filter(v => v.total_score !== 0).map(v => ({
              value: v.total_score,
              label: {
                show: true,
                color: '#00B0FF',
                formatter: function() {
                  return v.total_score === 0 ? '' : v.total_score + '分'
                },
                position: v.total_score < 5 ? 'right' : 'inside'
              },
              itemStyle: {
                color: '#DAEFF9'
              }
            }))

          },
          {
            type: 'bar',
            stack: 'total',
            barMaxWidth: 50,
            barMinHeight: 35,
            emphasis: {
              focus: 'series'
            },
            data: this.answer_list.filter(v => v.total_score !== 0).map(v => ({
              value: parseInt(v.runk * 100),
              label: {
                show: true,
                color: '#C176EC',
                formatter: function() {
                  return parseInt(v.runk * 100) === 0 ? '未失分' : `失分率：${parseInt(v.runk * 100)}%`
                },
                position: v.false_score < 5 && v.total_score < 5 ? 'right' : 'insideLeft'
              },
              itemStyle: {
                color: 'rgba(128, 128, 128, 0)'
              }
            }))
          },
        ]
      })
    },
    async init() {
      let {error, data} = await analyInfo({
        report_id: this.id,
        type: this.type
      })
      if (error === 0) {
        this.score = data.score
        this.rank = data.rank
        this.my_answer_time = data.my_answer_time
        this.answer_time_rank = data.answer_time_rank

        this.weak_list = data?.weak_list || []
        
        // 失分题数
        this.answer_false_num = data?.answer_false_num || []

        // 失分率
        this.answer_list = data?.answer_list || []
        this.flag_name = data?.flag_name || ''

        this.initWrongDiffDistrEchart()
        this.initWordCloundEchart()
        this.renderLatex()

        // this.detail()
      }
    },
    /**生成词云图 */
    initWordCloundEchart() {
      let elem = document.querySelector('.boruodian-container')
      if (!elem) {
        return
      }
      if (this.weak_list.length === 0) {
        return
      }
      let myChart = echarts.init(elem)

      let option = {
        series: [
          {
            type: 'wordCloud',
            left: 'center',
            top: 'center',
            height: '100%',
            sizeRange: [18, 26],
            rotationRange: [0, 0],
            rotationStep: 45,
            gridSize: 16,
            drawOutOfBound: false,
            layoutAnimation: true,
            // Global text style
            textStyle: {
              fontFamily: 'sans-serif',
              fontWeight: 'bold',
              // Color can be a callback function or a color string
              color: function () {
                // Random color
                return 'rgb(' + [
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160),
                  Math.round(Math.random() * 160)
                ].join(',') + ')'; 
              }
            },
            emphasis: {
              focus: 'self',
              textStyle: {
                textShadowBlur: 10,
                textShadowColor: '#333'
              }
            },
            data: this.originWeakList.filter(v => v.name.indexOf('\\') === -1).map((v, idx) => ({
              value: idx,
              name: v.name,
              textStyle: {
                color: v.color
              }
            }))
          },
        ],
      };
      myChart.setOption(option, true);
    },
    initWrongKnowEchart(cName, list, idx) {
      let elem = document.querySelector(cName)
      if (!elem) {
        return
      }
      this['myChart' + idx] = echarts.init(elem)
      this['myChart' + idx].setOption({
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            let data = params.data
            return `${data.name}：${params.percent}%`
          },
          confine: 'true',
          textStyle: {
            fontSize: 16
          }
        },
        legend: {
          left: '0%',
          show: false
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '55%'],
            selectedMode: 'single',
            label: {
              show: true,
              formatter:'{d}%',
              textStyle: {
                fontSize: 16
              }
            },
            data: list.map(v => ({
              value: v.runk * 100, 
              name: v.kp_name, 
              order_ids: v.order_ids || [],
              id: v.id
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
          }
        ]
      })
      if (idx === 5 || (!this.isFiveKnow && idx === 3)) {
        return
      }
      if (!this.isBindEvtObj[idx]) {
        this.isBindEvtObj[idx] = true
        this['myChart' + idx].on('click', (param) => {
          this.queryKnowDetail({
            report_id: this.id,
            pid: param.data.id,
            type: idx
          })
        })
      }
    },
    // 获取二级/三级/四级/五级知识点详情
    async queryKnowDetail({type, ...args}) {
      let {error, data = []} = await getKnowledgeLevel({
        ...args,
        type
      })
      if (error === ERROR_CODE) {
        if (type === 1) {
          this.knowledge_level_2 = data
          this.knowledge_level_3 = []
          this.knowledge_level_4 = []
          this.knowledge_level_5 = []
          this.$nextTick(() => {
            this.isBindEvtObj[1] = false
            this.isBindEvtObj[2] = false
            this.isBindEvtObj[3] = false
            this.isBindEvtObj[4] = false
            this.myChart2 && this.myChart2.dispose()
            this.myChart3 && this.myChart3.dispose()
            this.myChart4 && this.myChart4.dispose()
            this.myChart5 && this.myChart5.dispose()

            this.initSecondWrongKnowEchart()
          })
        } else if (type === 2) {
          this.knowledge_level_3 = data
          this.knowledge_level_4 = []
          this.knowledge_level_5 = []
          this.$nextTick(() => {
            this.isBindEvtObj[2] = false
            this.isBindEvtObj[3] = false
            this.isBindEvtObj[4] = false
            this.myChart3 && this.myChart3.dispose()
            this.myChart4 && this.myChart4.dispose()
            this.myChart5 && this.myChart5.dispose()

            this.initThirdWrongKnowEchart()
          })
        } else if (type === 3) {
          this.knowledge_level_4 = data
          this.knowledge_level_5 = []
          this.$nextTick(() => {
            this.isBindEvtObj[3] = false
            this.isBindEvtObj[4] = false
            this.myChart4 && this.myChart4.dispose()
            this.myChart5 && this.myChart5.dispose()

            this.initFourWrongKnowEchart()
          })
        } else {
          this.knowledge_level_5 = data
          this.$nextTick(() => {
            this.isBindEvtObj[4] = false
            this.myChart5 && this.myChart5.dispose()

            this.initFiveWrongKnowEchart()
          })
        }
      }
    },
    // 一级知识点错题数量
    initFirstWrongKnowEchart() {
      this.initWrongKnowEchart('.first-level-know', this.knowledge_level_1, 1)
    },
    // 二级知识点错题数量
    initSecondWrongKnowEchart() {
      this.initWrongKnowEchart('.second-level-know', this.knowledge_level_2, 2)
    },
    // 三级知识点错题数量
    initThirdWrongKnowEchart() {
      this.initWrongKnowEchart('.thrid-level-know', this.knowledge_level_3, 3)
    },
    // 四级知识点错题数量
    initFourWrongKnowEchart() {
      this.initWrongKnowEchart('.four-level-know', this.knowledge_level_4, 4)
    },
    // 五级知识点错题数量
    initFiveWrongKnowEchart() {
      this.initWrongKnowEchart('.five-level-know', this.knowledge_level_5, 5)
    },
    /**我与同学掌握差距较大的知识点 */
    initMyKnowEchart() {
      let elem = document.querySelector('.know-anlay-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)

      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let obj1 = params[0]
            if (params.length === 1) {
              return `${obj1.name}<br>${obj1.marker}${obj1.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${obj1.value}%`
            }
            let obj2 = params[1]
            return `${obj1.name}<br>${obj1.marker}${obj1.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${obj1.value}%<br>${obj2.marker}${obj2.seriesName}&nbsp;&nbsp;&nbsp;&nbsp;${obj2.value}%`
          },
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.my_false_know_list.list.map(v => v.name),
          axisLabel: { 
            // interval: 0 
            rotate: 45
          },
        },
        yAxis: {
          type: 'value',
          name: '%',
          boundaryGap: [0, 0.01]
        },
        series: [
          {
            name: '我的',
            type: 'bar',
            data: this.my_false_know_list.list.map(v => parseInt(v.my_runk * 100)),
            barWidth: 30,
            barMinHeight: 5,
            itemStyle: {
              normal: {
                color: '#00B0FF'
              }
            },
            barGap: '50%'
          },
          {
            name: '平均',
            type: 'bar',
            data: this.my_false_know_list.list.map(v => parseInt(v.student_runk * 100)),
            barWidth: 30,
            barMinHeight: 5,
            itemStyle: {
              normal: {
                color: '#FF9858'
              }
            },
            barGap: '50%'
          }
        ]
      })
    },
    /**准确率对比 */
    initCorrectEchart() {
      return
      let elem = document.querySelector('.correct-comp-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        color: ['#1FA3FF', '#C176EC'],
        title: {
          left: 'center',
          textStyle: {
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            let obj1 = params[0]
            let str = obj1.axisValue
            params.forEach(v => {
              str += `<br /><span style="display:inline-block;margin-right:5px;width:10px;height:10px;background-color:${v.color};border-radius:5px"></span>${v.seriesName}：${v.value}%`
            })
            return str
          },
          textStyle: {
            fontSize: 16
          }
        },
        legend: {
          data: ['平均准确率', '我的准确率'],
          x: 'right',
          textStyle: {
            fontSize: 16
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.my_true_question_rank.filter(v => v.my_rank != '0.00' || v.student_rank !== '0.00').map(v => v.title),
          axisLabel: {
            fontSize: 16,
            interval: 0,
            rotate: 40
          },
        },
        yAxis: [
          {
            name: '%',
            type: 'value',
            max: 100,
            axisLabel: {
              fontSize: 16
            }
          },
          {
            type: 'value',
            axisLabel: {
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: '平均准确率',
            type: 'line',
            data: this.my_true_question_rank.filter(v => v.my_rank != '0.00' || v.student_rank !== '0.00').map(v => parseInt(v.student_rank * 100)),
            smooth: true,
          },
          {
            name: '我的准确率',
            type: 'line',
            data: this.my_true_question_rank.filter(v => v.my_rank != '0.00' || v.student_rank !== '0.00').map(v => parseInt(v.my_rank * 100)),
            smooth: true,
          }
        ]
      })
    },
    /**答题难度对比 */
    initAnswerDiffEchart() {
      let elem = document.querySelector('.answer-diff-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        title: {
          left: 'center',
          textStyle: {
            fontWeight: 'normal'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          data: ['试卷难度', `${this.leftRadioValue}平均答题难度`, '我的答题难度'],
          axisLabel: {
            fontSize: 16
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 30,
            data: [
              {
                value: this.report_level.report_level,
                itemStyle: {
                  color: '#00B0FF'
                },
                label: {
                  show: true,
                  position: 'right',
                  formatter:'{c}',
                  textStyle: {
                    fontSize: 16,
                    color: '#00B0FF'
                  },
                },
              },
              {
                value: this.report_level.my_student_level,
                itemStyle: {
                  color: '#FF9858'
                },
                label: {
                  show: true,
                  position: 'right',
                  formatter:'{c}',
                  textStyle: {
                    fontSize: 16,
                    color: '#FF9858'
                  },
                },
              },
              {
                value: this.report_level.my_level,
                itemStyle: {
                  color: '#C176EC'
                },
                label: {
                  show: true,
                  position: 'right',
                  formatter:'{c}',
                  textStyle: {
                    fontSize: 16,
                    color: '#C176EC'
                  },
                },
              }
            ],
          }
        ]
      })
    },
    /**我的错题难易度占比 */
    initWrongDiffEchart() {
      let elem = document.querySelector('.first-line-right-bottom-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        title: {
          left: 'center',
          textStyle: {
            fontWeight: 'normal'
          }
        },
        xAxis: {
          type: 'category',
          data: this.my_false_question_level.filter(v => v.score !== 0).map(v => v.title),
          axisLabel: {
            fontSize: 16
          },
        },
        yAxis: {
          name: '%',
          type: 'value',
          max: 100,
          axisLabel: {
            fontSize: 16
          }
        },
        series: [
          {
            data: this.my_false_question_level.filter(v => v.score !== 0).map(
              (v, i) => ({
                value: parseInt(v.rank * 100), 
                itemStyle: {color: COLORS[i]}
              })
            ),
            type: 'bar',
            barMaxWidth: 20,
            label: {
              show: true,
              position: 'top',
              formatter:'{c}%',
              textStyle: {
                fontSize: 16
              }
            }
          }
        ]
      })
    },
    /**试卷难易度分布 */
    initPaperDiffEchart() {
      let elem = document.querySelector('.first-line-left-bottom-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        title: {
          left: 'center',
          textStyle: {
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {d}%',
          textStyle: {
            fontSize: 16
          }
        },
        legend: {
          left: '0%',
          show: false
        },
        series: [
          {
            name,
            type: 'pie',
            radius: [0, '70%'],
            selectedMode: 'single',
            data: this.question_level.filter(v => v.score !== 0).map(
              (v, i) => ({
                value: v.rank, 
                name: v.title, 
                itemStyle: {color: COLORS[i]}
              })
            ),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              position: 'inner',
              fontSize: 16
            }
          }
        ]
      })
    },
    /**试卷得分 */
    initPaperScoreEchart() {
      let elem = document.querySelector('.paper-echart')
      if (!elem) {
        return
      }
      var myChart = echarts.init(elem)
      myChart.setOption({
        legend: {},
        grid: {
          top: '2%',
          left: '3%',
          right: '4%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          data: this.score_list.map(v => v.title),
          axisLabel: {
            fontSize: 16
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 45,
            data: this.score_list.map(v => ({
              value: v.score,
              itemStyle: {
                color: v.color,
              },
              label: {
                show: true,
                position: 'right',
                formatter:'{c}分',
                textStyle: {
                  fontSize: 16,
                  color: v.color
                }
              }
            }))
          }
        ]
      })
    },
    handleChangeRightSection() {
      this.$router.push({
        path: '/paperfenxi',
        query: {id: this.id}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.head-sort {
  justify-content: center;
  align-items: center;
  div {
    margin-left: 4px;
    i {
      height: 13px;
      line-height: 13px;
      cursor: pointer;
      color: #c8c8c8;
      &:first-child {
        &.active {
          color: #3191FF;
        }
      }
      &:last-child {
        &.active {
          color: #3191FF;
        }
      }
    }
  }
}
.red {
  color: red;
}
.green {
  color: green;
}
.footer {
  margin-top: 70px;
  span {
    display: inline-block;
    width: 104px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    border: 1px solid #00B0FF;
    color: #00B0FF;
    font-size: 20px;
    cursor: pointer;
    img {
      width: 15px;
    }
  }
}
.my-table {
  margin-top: 15px;
  margin-bottom: 30px;
}
.answer-echart {
  margin-top: 60px;
}
.switch-know {
  margin-top: 9px;
}
$light: #C176EC;
.blue {
  color: blue;
}
.red {
  color: red;
}
.latex ::v-deep .mq-root-block, .latex ::v-deep .mq-math-mode .mq-root-block {
  display: contents;
}

table {
  margin-top: 45px;
  border-radius: 26px;
  border-spacing: 0;
  border-collapse: separate;
  overflow: hidden;
  border: 1px solid #CBE3FF;
  thead {
    // height: 75px;
    padding: 22px 0;
    background: #E1EFFF;
    td {
      border-top: 1px solid transparent;
    }
  }
  td {
    // height: 90px;
    // line-height: 25px;
    padding: 22px 0;
    vertical-align: middle;
    text-align: center;
    font-size: 18px;
    color: #4B4B4B;
    border-right: 1px solid #CBE3FF;
    border-top: 1px solid #CBE3FF;
    &:last-child {
      border-right: 1px solid transparent;
    }
  }
}

.report {
  background: white;
  border-radius: 30px;
  margin-top: 40px;
  padding: 45px 50px 50px;
  .report-header {
    .header-container {
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .el-radio-group {
        width: 200px;
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
    }
    .header-detail {
      justify-content: space-between;
      justify-content: center;
      position: relative;
      margin-top: 20px;
      h2 {
        font-size: 36px;
        color: #333;
      }
      .el-radio-group {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .header-desc {
      justify-content: center;
      margin-top: 10px;
      align-items: center;
      span {
        margin-left: 10px;
        color: #7B7B7B;
        font-size: 24px;
      }
      .iconfont {
        color: #7b7b7b;
        font-size: 22px;
      }
    }
  }
  .light {
    color: $light;
  }
  .echart {
    height: 400px;
  }
  .section {
    margin-top: 50px;
  }
  .section-header {
    display: inline-block;
    position: relative;
    padding: 0 2px;
    margin-bottom: 3px;
    font-size: 24px;
    color: #333;
    font-weight: bold;
    span:last-child {
      position: relative;
    }
    .bg {
      position: absolute;
      height: 10px;
      left: 0;
      right: 0;
      bottom: -3px;
      background: linear-gradient(90deg, #ACE5FF 0%, #38C2FF 100%);
      opacity: 0.6;
    }
  }
  .tip-container {
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(87, 87, 87, 0.08);
    padding: 30px;
    border-radius: 20px;
    position: relative;
    font-size: 18px;
    color: #787878;
    & > div {
      line-height: 1.6;
      position: relative;
      .cover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
      }
      ::v-deep .mq-math-mode, ::v-deep .mq-math-mode .mq-editable-field {
        font-family: auto;
      }
      ::v-deep .red {
        color: #00B0FF;
        display: contents;
        font-size: 18px;
        .mq-root-block, .mq-math-mode .mq-root-block {
          display: contents;
        }
      }
      ::v-deep .blue {
        color: #FF9858;
      }
      ::v-deep .yellow {
        color: #78CF78;
      }
      ::v-deep .green {
        color: #C176EC;
      }
    }
    .b {
      color: #333;
      flex-shrink: 0;
    }
    .sjx {
      position: absolute;
      left: 50%;
      top: -15px;
      display: inline-block;
      width: 0;
      height: 0;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      border-left: 15px solid transparent;
      transform: translateX(-50%);
    }
  }
  .card-header {
    align-items: center;
    .card-desc {
      color: #999;
      font-size: 18px;
      margin-left: 20px;
    }
    .card-btn {
      font-size: 16px;
      color: #00B0FF;
      align-items: center;
      padding-left: 10px;
      padding-right: 5px;
      cursor: pointer;
      &.close {
        color: #999;
      }
    }
  }
  .paper-over {
    .paper-container {
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0px 7px 31px rgba(203, 203, 203, 0.16);
      border-radius: 17px;
      margin: 30px 70px 0;
      padding: 18px 60px 40px;
      align-items: center;
      h4 {
        color: #7B7B7B;
        font-size: 24px;
      }
      & > span {
        color: #00B0FF;
        margin-top: 10px;
        font-size: 54px;
        align-items: center;
        span {
          font-size: 48px;
        }
      }
      .ranking {
        background: #fff;
        padding: 40px 30px;
        border-radius: 20px;
        position: relative;
        margin-top: 25px;
        box-shadow: 0 0 29px 0 rgba(87, 87, 87, 0.08);
        width: 100%;
        box-sizing: border-box;
        .sjx {
          position: absolute;
          left: 50%;
          top: -15px;
          display: inline-block;
          width: 0;
          height: 0;
          border-right: 15px solid transparent;
          border-bottom: 15px solid white;
          border-left: 15px solid transparent;
          transform: translateX(-50%);
        }
      }
      .ranking-content {
        align-items: center;
        width: 100%;
        color: #7b7b7b;
        &:last-child {
          margin-top: 26px;
        }
        .ranking-content-title {
          font-size: 20px;
          margin-right: 24px;
          flex-shrink: 0;
        }
        .ranking-content-right {
          width: 100%;
          position: relative;
          .el-progress {
            flex-grow: 1;
          }
          .ranking-content-desc {
            position: absolute;
            top: 50%;
            transform: translate(-14px, -50%);
            img {
              width: 28px;
              height: 32px;
            }
            span {
              position: absolute;
              right: 2%;
              top: 38px;
              color: #00B0FF; 
              font-size: 14px;
              width: 200px;
              text-align: right;
              &.mini-rank {
                left: -134px;
              }
            }
          }
        }
      }
      .paper-header {
        width: 100%;
        font-size: 20px;
        color: #7b7b7b;
        span:last-child {
          color: #00B0FF;
          margin-left: 24px;
        }
      }
    }
    .wrong-question-amount {
      margin-top: 60px;
      align-items: center;
      color: #7b7b7b;
      h4 {
        font-size: 24px;
      }
      span {
        font-size: 20px;
        margin-top: 10px;
      }
      .wrong-diff-echart {
        width: 60%;
        height: 450px;
        margin-top: 50px;
      }
    }
  }
  .boruodian {
    .boruodian-header {
      justify-content: space-between;
      align-items: center;
    }
    .color1 {
      background: #00B0FF;
    }
    .color2 {
      background: #FF9858;
    }
    .color4 {
      background: #5CC55C;
    }
    .color3 {
      background: #C176EC;
    }
    .boruodian-container {
      height: 500px;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #999;
      opacity: 0.8;
    }
    p {
      font-size: 18px;
      color: #999;
      text-align: center;
      margin-top: 45px;
      span {
        &:nth-child(1) {
          color: #00B0FF;
        }
        &:nth-child(2) {
          color: #FF9858;
        }
        &:nth-child(3) {
          color: #78CF78;
        }
        &:nth-child(4) {
          color: #C176EC;
        }
      }
    }
    .tip-container {
      margin-top: 25px;
    }
  }
  .study-suggestion {
    p {
      margin-top: 15px;
      font-size: 18px;
      color: #333;
      line-height: 1.4;
    }
  }
  .detail-btn {
    justify-content: center;
    margin-top: 74px;
    span {
      padding: 8px 20px;
      background: #00B0FF;
      border-radius: 100px;
      color: white;
      cursor: pointer;
      align-items: center;
      .iconfont {
        margin-left: 6px;
      }
      img {
        width: 18px;
        margin-left: 3px;
      }
    }
  }
  .detail-container {
    border: 1px solid #C4C4C4;
    margin-top: 65px;
    border-radius: 20px;
    padding: 25px 20px;
    .detail-header {
      font-size: 16px;
      color: #333;
      align-items: center;
      margin-bottom: 15px;
      .line {
        width: 2px;
        height: 12px;
        margin-right: 10px;
        border-radius: 100px;
      }
    }
    .know-tip {
      margin-top: 40px;
      font-size: 18px;
      color: #999;
    }
    & > h4 {
      justify-content: center;
      color: #00B0FF;
      align-items: center;
      font-size: 24px;
      .iconfont {
        margin-right: 5px;
        font-size: 22px;
      }
      span {
        span {
          font-size: 18px;
          color: #5ACCFF;
        }
      }
    }
    .paper-score {
      .card-header {
        margin-top: 40px;
      }
      .paper-echart {
        margin-top: 60px;
        height: 300px;
      }
      .score-footer {
        margin-top: 40px;
        justify-content: center;
        font-size: 18px;
        color: #999;
      }
    }
    .paper-diff-analy {
      margin-top: 60px;
      .paper-diff-analy-header {
        margin-top: 40px;
      }
      .paper-diff-analy-container {
        margin-top: 35px;
        // .first-line {
        //   .first-line-top {
        //     .line {
        //       background: #3AA1FF;
        //     }
        //     .top-row {
        //       margin-top: 18px;
        //       justify-content: space-between;
        //       width: 50%;
        //       font-size: 16px;
        //       color: #333;
        //       .iconfont {
        //         color: #FFD67A;
        //       }
        //     }
        //   }
        //   .first-line-left {
        //     width: 50%;
        //     .first-line-left-bottom-echart {
        //       height: 350px;
        //       margin-top: 45px;
        //       width: 80%;
        //     }
        //   }
        //   .first-line-right {
        //     width: 50%;
        //     .first-line-right-bottom-echart {
        //       height: 350px;
        //       margin-top: 45px;
        //     }
        //   }
        // }
        // .second-line {
        //   margin-top: 75px;
        //   justify-content: space-between;
        //   .second-line-top {
        //     width: 40%;
        //     .line {
        //       background: #FF9858;
        //     }
        //   }
        //   .second-line-bottom {
        //     width: 50%;
        //     .line {
        //       background: #FF9858;
        //     }
        //   }
        //   .correct-comp-echart, .answer-diff-echart {
        //     height: 300px;
        //   }
        //   .correct-comp-echart {
        //     width: 40%;
        //     width: 100%;
        //   }
        //   .answer-diff-echart {
        //     width: 50%;
        //     width: 100%;
        //   }
        // }
        // .tip-container {
        //   margin-top: 45px;
        // }
        .paper-score-echart {
          height: 500px;
        }
      }
    }
    .know-anlay {
      margin-top: 95px;
      .know-anlay-container {
        margin-top: 45px;
        justify-content: space-between;
        .know-anlay-top {
          justify-content: space-between;
          .line {
            background: #C176EC;
          }
          .know-anlay-echart {
            height: 500px;
            margin-top: 35px;
          }
          .tip-container {
            margin-top: 95px;
          }
        }
        .know-anlay-bottom {
          margin-top: 70px;
          .line {
            background: #169FFF;
          }
          .know-container {
            margin-top: 45px;
            justify-content: space-around;
            flex-wrap: wrap;
            .first-level-know,
            .second-level-know,
            .thrid-level-know,
            .four-level-know,
            .five-level-know {
              width: 30%;
              height: 300px;
            }
            .four-level-know, .five-level-know {
              margin-top: 20px;
            }
            &.isPad {
              .first-level-know,
              .second-level-know,
              .thrid-level-know,
              .four-level-know,
              .five-level-know {
                width: 100%;
              }
            }
          }
          p {
            color: #999;
            font-size: 18px;
            margin-top: 55px;
            text-align: center;
          }
        }
      }
    }
    .analy {
      margin-top: 90px;
      .anlay-container {
        .anlay-container-top {
          justify-content: space-between;
          margin-top: 55px;
          .line {
            background: #169FFF;
          }
          .anlay-container-top-left {
            .anlay-container-top-left-echart {
              height: 320px;
              margin-top: 30px;
            }
          }
          .anlay-container-top-right {
            margin-top: 60px;
            .anlay-container-top-right-echart {
              height: 500px;
              margin-top: 30px;
            }
          }
        }
        .tip-container {
          margin-top: 40px;
        }
      }
    }
    .answer-paper-duration {
      margin-top: 64px;
      .answer-paper-echart {
        height: 300px;
        margin-top: 40px;
      }
    }
  }
}
</style>